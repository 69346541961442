div.book-button {
    position: fixed;
    right: -24px;
    top: 50vh;
    @include prefix(transition, right .5s);
    &:hover {
        right: 0;
    }

    // tablet
	@include media-breakpoint-down(lg) {
		top: 30vh;
	}

    // mobile
	@include media-breakpoint-down(xs) {
		top: 20vh;
	}

    &::after {
        content: '';
        width: 40px;
        height: 100%;
        right: -38px;
        background-color: $tertiary;
        display: inline-block;
        position: absolute;
        top: 0;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 100px;
        background-color: transparent;
        box-shadow: 0 6px 12px rgba($black, .25);
        border-radius: 5px 0 0 5px;
        text-decoration: none;
        background-image: url('/images/&.svg');
        background-position: center;
        background-size: contain;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        color: $white;

        .list-item-title {
            width: 56px;
            line-height: 56px;
            margin-bottom: 5px;
            z-index: 1;
        }
    }
}