// footer
.footer {
    .footer-outro {
        padding: 50px 0;

        // footer logos
        .partners-list {
            @extend .list-unstyled;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(xs) {
                max-width: 260px;
                margin: auto;
            }

            li,
            .list-item {
                text-align: center;
                padding: 0 .5rem;

                @include media-breakpoint-down(xs) {
                    flex: 0 0 33.33333%;
                    max-width: 33.33333%;
                    margin-bottom: 1rem;
                }

                img {
                    width: 73px;

                    @include media-breakpoint-down(xs) {
                        width: 54px;
                    }
                }

                .list-item-title {
                    display: none;
                }
            }
        }
    }

    // footer links
    .footer-sitelinks {
        font-size: 15px;
        padding: 50px 0;

        @include media-breakpoint-down(xs) {
            padding: 0;
        }

        .column.four {
            @include media-breakpoint-down(xs) {
                margin-bottom: 0 !important;
            }
        }

        .footer-title {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1rem;
        }
    }

    // footer socail media
    .footer-logolink {
        padding: 0 0 20px;

        .list {
            .list-item {
                margin-bottom: 10px;

                &:not(:last-child) {
                    margin-right: 5px;

                    @include media-breakpoint-down(lg) {
                        margin-right: 10px;
                    }

                }

                .link {
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    display: inline-block;
                    border: 1px solid $black;
                    border-radius: 50%;
                    color: $black;
                    font-size: 20px;
                }
            }
        }
    }

    // footer copyright
    .footer-copyright {
        padding: 25px 0;

        .footer-link {
            .list {
                justify-content: center;

                .list-item {
                    &:not(:last-child) {
                        margin-right: 2rem;
                    }

                    font-size: 12px;

                    .link {
                        text-decoration: none;
                        color: $black;
                    }
                }
            }
        }
    }
}