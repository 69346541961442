// mini-sab
.mini-sab {
	height: 270px;
	// tablet
	@include media-breakpoint-up(sm) {
		height: 90px;
	}
	// desktop
	@include media-breakpoint-up(lg) {
		height: 0;
	}

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			width: 100%;
			position: relative;
			margin: 0;
			padding: 28px 1rem 22px;
			border-radius: 4px;
			background: $white;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;
			top: -190px;

			// tablet
			@include media-breakpoint-up(sm) {
				top: -180px;
			}

			// desktop
			@include media-breakpoint-up(lg) {
				top: -190px;
			}

			@include media-breakpoint-up(xl) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}
}

// not homepage
&:not(.home) {
	.mini-sab {	
		.container {
			.container-holder {
				top: -120px;

				// tablet
				@include media-breakpoint-up(sm) {
					top: -90px;
				}

				// desktop
				@include media-breakpoint-up(lg) {
					top: -130px;
				}
			}
		}
	}
}

// hide on detail/overview pages
&.bundle-detail {
	&.service-detail,
	&.accommodation-detail {
		.mini-sab {
			display: none;
		}
	}
}
&.bundle-overview {
	&.service-overview,
	&.accommodation-category-overview {
		.mini-sab {
			display: none;
		}
	}
}