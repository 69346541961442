// bg-*
// =========================================================================
section {
	position: relative;
	overflow-x: clip;

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 50px 0;
	}
}

// .lead-section+.content-section,
// .lead-section+.bundle-overview-section {
// 	padding-top: calc(-6vh + 30px);
// }

// lead-section
// =========================================================================
.lead-section {
	padding: 50px 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		} 

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 0 auto;

	// left bg of section
	&::before {
		@extend %left-leaf;		
	}

	// right bg of section
	&::after {
		@extend %right-leaf;
	}
}

.sub-section {
	padding-top: 50px;
	padding-bottom: 50px;

	@include media-breakpoint-down(sm) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 50px 0;
}

// banner-section
// =========================================================================
.banner-section {
	margin-top: 50px;
	margin-bottom: 50px;

	// mobile
	@include media-breakpoint-down(xs) {
		margin-bottom: 0;
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 0 0 50px;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}

	// left bg of section
	&::before {
		@extend %left-leaf;		
	}

	// right bg of section
	&::after {
		@extend %right-leaf;
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 0 0 50px;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding-bottom: 30px;
	@include media-breakpoint-up(sm) {
		padding-bottom: 50px;
	}
}

// booking-section
// =========================================================================
.booking-section {
	padding: 50px 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 50px 0;
}
