// content lists 
.wysiwyg {
	ul {
		&:not(.custom-list),
		&:not(.custom-list-extra) {
			@extend .list-unstyled;
			margin-bottom: 1.5rem;
			li {
				position: relative;
				padding: 0 0 0 1.5rem;
				@include prefix(transition, padding .5s);
		
				&::before {
					content: '\f178';
					position: absolute;
					top: 0;
					left: 0;
					font-family: $font-awesome;
					color: $tertiary;
					font-weight: 900;
				}
	
				&:hover {
					padding: 0 0 0 2rem;
				}
			}
		}
	}
}

// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

.footer-list {
	@extend .list-unstyled;
	.list-item {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		@include prefix(transition, padding-left .5s);

		&::after {
			@include prefix(transition, left 0.5s);
			content: '\f178';
			font-family: $font-awesome;
			position: absolute;
			top: 2px;
			left: -1rem;
			color: $black;
		}
		&:hover {
			padding-left: 20px;
			&::after {
				left: 0;
			}
		}

		.list-link {
			color: $black;
		}
	}
}