// left leaf section bg 
%left-leaf {
    content: '';
    display: inline-block;
    position: absolute;
    top: -5rem;
    right: 0;
    background-image: url('/images/body-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 42vw;
    height: 1150px;
    z-index: -1;

    // mobile
    @include media-breakpoint-down(xs) {
        top: -20rem;
        right: -70px;
        width: 100%;
        height: 900px;
    }
}

// right leaf section bg
%right-leaf {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    background-image: url('/images/body-bg.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    width: 30vw;
    height: 1150px;
    z-index: -1;
    @include prefix(transform, translateY(-50%));

    // mobile
    @include media-breakpoint-down(xs) {
        top: 30rem;
        left: -130px;
        width: 100%;
        height: 900px;
        transform: none;
    }
}