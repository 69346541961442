// container
.container {
	@include media-breakpoint-down(lg) {
		padding-left: 30px;
		padding-right: 30px;
	}

	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 960px;
		margin: auto;
	}

	&.container-wide {
		max-width: 1320px;
		margin: auto;
	}

	&.container-intro {
		max-width: 720px;
	}

	&.container-outro {
		max-width: 720px;
		margin: auto;
	}
}

// heading
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $font-family-primary;
	font-weight: 400;
}

// paragraph
p {
	// min-height: 1rem;
	font-family: $font-family-secondary;
	// margin-bottom: 0;
}

// links
a {
	font-family: $font-family-secondary;
	text-decoration: underline;
}

// lists
ol, ul, dl {
	margin: 0;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {}

// .container-holder
.container-holder {
	margin-bottom: 0 !important;

	> .column {
		&:not(.default) {
			@include media-breakpoint-down(xs) {
				margin-bottom: 2rem !important;
			}
		}
	}
}