// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 670px;
	min-height: 540px;
	max-height: 1080px;

	// mobile
	@include media-breakpoint-down(sm) {
		height: 540px;
	}
}

.eyecatcher.large {
	margin-bottom: 100px;

	// desktop
	@include media-breakpoint-up(lg) {
		margin-bottom: 50px;
	}
}

// owl-carousel
.owl-carousel {
	.owl-item {
		.item {
			align-items: flex-end;

			.owl-caption {
				text-align: left;
				margin-bottom: 7rem;

				@include media-breakpoint-up(lg) {
					margin-bottom: 10rem;
				}

				.owl-title,
				.owl-subtitle {
					opacity: 0;
					margin-bottom: 0;
					@include prefix(transition, all 3s);
				}

				.owl-title {
					font-size: 20px;
					font-family: $font-family-secondary;
					font-weight: 400;
					@include prefix(transform, translateY(-6rem));
					@include prefix(transition-delay, .3s);
				}

				.owl-subtitle {
					font-size: 3rem;
					font-weight: 400;
					max-width: 550px;
					line-height: 61px;
					@include prefix(transform, translateY(-5rem));

					// mobile
					@include media-breakpoint-down(sm) {
						font-size: 40px;
						line-height: normal;
					}
				}
			}
		}

		&.active {
			.item {
				.owl-caption {

					.owl-title,
					.owl-subtitle {
						opacity: 1;
						@include prefix(transform, translateY(0));
					}
				}
			}
		}
	}

}

// other banner sections
.owl-carousel:not(.eyecatcher-owl-carousel) {
	min-height: 760px;

	// mobile
	@include media-breakpoint-down(sm) {
		min-height: 540px;
	}

	.owl-item {
		.item {
			min-height: 760px;
			
			// tablet
			@include media-breakpoint-down(lg) {
				padding: 2.5rem;
			}

			// mobile
			@include media-breakpoint-down(sm) {
				min-height: 540px;
				padding: 20px 0;
			}
			.owl-caption {
				margin-bottom: 0;
			}
		}
	}
}