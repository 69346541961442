// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	font-family: $font-family-tertiary;
	font-size: 14px;
	box-shadow: $shadow;

	@include media-breakpoint-down(lg) {
		font-size: 1rem;
	}

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					justify-content: space-between;
					padding: 2rem 30px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 32px;
			padding: 0.25rem 0;
			text-align: left;
			color: $black;
		}
	}

	// logo
	.logo {
		position: absolute;
		top: 0;
		padding: 10px;
		left: 50%;
		@include prefix(transform, translateX(-50%));
		width: max-content;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		.navbar-nav {
			&>.nav-item {
				&>.nav-link {
					color: $black;
					text-decoration: none;
				}
				&:hover,
				&.active {
					&>.nav-link {
						color: $primary;
					}
				}
			}
		}

		.dropdown-menu {
			.nav-item {
				.nav-link {
					padding: 0;
					position: relative;
					overflow: hidden;
					margin-bottom: 10px;
					text-decoration: none;
					@include prefix(transition, 0.5s);

					&::after {
						@include prefix(transition, 0.5s);
						content: '\f178';
						font-family: $font-awesome;
						position: absolute;
						top: 0;
						left: -1rem;
					}
				}

				&:hover,
				&.active {
					.nav-link {
						padding-left: 1.4rem;

						&::after {
							left: 0;
						}
					}
				}
			}
		}

		// tablet + mobile
		@include media-breakpoint-down(lg) {
			@include prefix(transition, all .5s);
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100% !important;
			visibility: hidden;
			z-index: -1;

			.navbar-nav {
				position: relative;
				top: 0;
				left: -350px;
				bottom: 0;
				max-width: 300px;
				background: url('/images/body-bg.svg') $white no-repeat 60px 100px / 100%;
				padding: 20px;
				height: 100% !important;
				z-index: 2;
				@include prefix(box-shadow, $shadow);
				@include prefix(transition, left .5s);

				&::before {
					content: '\f00d';
					font-family: $font-awesome;
					width: 30px;
					height: 30px;
					line-height: 30px;
					font-size: 22px;
					text-align: center;
					position: absolute;
					right: -40px;
					color: $white;
					pointer-events: none;
				}

				.nav-item {
					&.active {
						.dropdown-menu {
							display: block;
							top: 0;
							left: 0;
						}
					}
					.dropdown-menu {
						position: relative !important;
						transform: unset !important;
						background: transparent;
						border: none;
						padding: 0;
						padding-left: 1rem;
						margin: 0;
					}

				}
			}

			&.collapse:not(.show) {
				display: unset;
			}

			&.collapsing,
			&.show {
				z-index: 1;
				background-color: rgba($black, .5);
				visibility: visible;

				.navbar-nav {
					left: 0;
					@include prefix(transition, left .5s);
				}
			}
		}

		// desktop
		@include media-breakpoint-up(xl) {
			.navbar-nav {
				width: 100%;

				&>.nav-item {
					@include media-breakpoint-up(lg) {
						&:last-child {
							margin-left: auto;
						}
					}

					&>.nav-link {
						color: $black;

						@include media-breakpoint-up(lg) {
							padding: 2.2rem 1rem;
						}
					}

					.dropdown-menu {
						background-image: url('/images/body-bg.svg');
						background-position: 60px center;
						background-size: 100%;
						background-repeat: no-repeat;
						border-radius: 0;
						min-width: 15rem;
						left: 0 !important;
						transform: none !important;

						.nav-item {
							padding: 10px;
						}

						&.show {
							&::before {
								right: unset;
								left: 19px;
							}

							&::after {
								right: unset;
								left: 20px;
							}
						}
					}

				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		.navbar-nav {
			margin-left: 15px;
			text-transform: uppercase;

			.nav-item {
				.nav-link {
					padding: 0;
					border-radius: unset;
					color: $black;
					border: none;
					text-decoration: none;

					&:hover:not(.active) {
						box-shadow: unset;
					}
				}

				.dropdown-menu {
					min-width: auto;
					padding: 5px;
					border-color: $white;
					border-radius: 0;
					box-shadow: $shadow;
					overflow: hidden;
					border: 1px solid #e1e1e1;

					.dropdown-item {
						text-decoration: none;
						padding: 0.3rem;

						&.active, &:active {
							background-color: $tertiary;
						}
					}
				}
			}
		}
	}
}