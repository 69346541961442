.card {
    background-color: unset;
    border: none;
    border-radius: 0;

    .card-image {
        padding-top: 100%;
        position: relative;

        .card-img-top,
        .card-img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            object-fit: cover;
        }
    }

    .card-body {
        padding: 15px 0 0;
        .card-caption {
            display: flex;
            flex-direction: column;

            .card-title, .card-title-link {
                order: 1;
                font-size: 30px;
                line-height: 41px;
                margin: 0 0 10px;
                text-transform: capitalize;
            }
            .card-subtitle {
                order: 0;
                font-size: 15px;
                font-family: $font-family-secondary;
                margin: 0;
                text-transform: uppercase;
            }

            .card-text,
            .card-description {
                order: 2;
                font-family: $font-family-secondary;
                .card-description-content {
                    font-size: 15px;
                    font-family: $font-family-secondary;
                }

                .list {
                    &.list-icons {
                        .list-item {
                            font-size: 15px;
                            font-family: $font-family-secondary;
                            .icon {
                                i {
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                } 
            }
        }

        .card-info {
            font-family: $font-family-secondary;
            .card-staydata {
                margin: 10px 0;
                li {
                    flex: 1 1 100%;
                    max-width: 100%;
                    font-size: 14px;
                    &::before {
                        display: none;
                    }
                }
            }
        }
        .card-buttons {
            display: none;
        }
    }
}