&.accommodation-detail {
    .lead-section  {
        .info {
            .properties {
                li {
                    i {
                        font-weight: 300;
                    }
              }
            }
        }
    } 
}