// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
    @extend .clearfix;

    .tbs-widget-label {
        margin: 0;

        .tbs-widget-label-span {
            display: none;
        }
    }

}
.search-book .tommy-zeb-wrapper * {
    font-family:  $font-family-secondary !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left {
    background: $white;
    border: 1px solid $primary;
    border-radius: 0;
    color: $black;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
    background: rgba($primary, 0.1);
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
    display: none;
}
.search-book .tommy-zeb-wrapper * h2 {
    font-size: 16px;
    font-weight: 400;
    color: $black;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left select, 
.search-book .tommy-zeb-wrapper .tommy-zeb-left input {
    border-radius: 3px;
    border: 1px solid $primary;
    background-color: $white;
}

// labels
.search-book .tommy-zeb-characteristic-choice-item .ng-binding,
.search-book .tommy-zeb-period .tommy-zeb-date-begin-label label,
.search-book .tommy-zeb-period .tommy-zeb-date-end-label label,
.search-book .tommy-zeb-accommodation-person-category-label {
    line-height: normal;
    color: $secondary;
    font-size: 14px;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
    margin-top: 0 !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn;
    @extend .btn-primary;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
    margin-bottom: 10px;
    color: $primary;
    font-size: 20px;
    font-weight: 700;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
    color: $secondary;
    font-weight: 700;
}

.tommy-zeb-right {

    .tommy-zeb-results {
        .tommy-zeb-results-label {
            margin-bottom: 1rem;
        }

        .tommy-zeb-result {
            &.ng-scope {
                background: transparent;
                padding: 0;
                border-radius: 0;

                @include media-breakpoint-up(lg) {
                    display: flex;
                }
            }

            .tommy-zeb-result-image {
                flex: 1 1 100%;
                max-width: 250px;
                overflow: hidden;
                border-radius: 0;

                @include media-breakpoint-down(lg) {
                    margin: 0 0 15px;
                }
                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }

                img {
                    border-radius: 0;
                    aspect-ratio: 1;
                    object-fit: cover;
                    @include prefix(transition, .5s);

                    &:hover {
                        @include prefix(transform, scale(1.05));
                    }
                }


            }

            .tommy-zeb-result-details {
                display: flex;
                flex-direction: column;
                padding: 0;

                @include media-breakpoint-up(xl) {
                    max-width: 520px;
                    flex: 1 1 100%;
                    padding: 0 20px;
                }

                .tommy-zeb-result-label.ng-binding {
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: 700;
                }

                .tommy-zeb-result-description {
                    .tommy-zeb-result-text {
                        ul {
                            @extend .list-unstyled;
                            margin-bottom: 1.5rem;
                            li {
                                position: relative;
                                padding: 0 0 0 1.5rem;
                                @include prefix(transition, padding .5s);
                        
                                &::before {
                                    content: '\f178';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    font-family: $font-awesome;
                                    color: $tertiary;
                                    font-weight: 900;
                                }
                    
                                &:hover {
                                    padding: 0 0 0 2rem;
                                }
                            }
                        }
                    }
                }
                

                .tommy-zeb-result-actions {
                    margin-top: auto;
                }

                .tommy-zeb-result-price.ng-binding {
                    color: $primary;
                    font-weight: 700;
                }


                .tommy-zeb-btn {
                    @extend .btn;
                    @extend .btn-primary;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
        max-width: 250px;
    }
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
        width: 520px;
    }
}
@media (min-width: 980px) and (max-width: 1199px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
       margin-bottom: 15px;
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
        margin-right: 0;
    }
}
